<template>
  <div v-if="!angemeldeterMitarbeiter" class="container-fluid tile-container">
    <div class="row mt-70-px">
      <div class="col-4"></div>
      <div class="col-4">
        <div class="row">
          <div class="col-xl-12 col-lg-6 col-md-6">
            <div class="tile-categorie-frame">
              <div class="tile-categorie">
                <div class="text-right m-4">
                  <div class="language-selection">
                    <b-button
                      variant="outline-primary"
                      class="mx-1"
                      size="sm"
                      @click="language = 'de'"
                      :class="language === 'de' ? 'active' : ''"
                      >DE</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                      size="sm"
                      @click="language = 'en'"
                      :class="language === 'en' ? 'active' : ''"
                      >EN</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                      size="sm"
                      @click="language = 'fr'"
                      :class="language === 'fr' ? 'active' : ''"
                      >FR</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                      size="sm"
                      @click="language = 'it'"
                      :class="language === 'it' ? 'active' : ''"
                      >IT</b-button
                    >
                  </div>
                </div>
                <div class="header text-center">
                  <span>{{ welcome[language] }}</span>
                </div>
                <div class="m-4">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span id="username" class="input-group-text">
                        <font-awesome-icon
                          icon="fa-duotone fa-user"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      name="username"
                      class="form-control"
                      :placeholder="usernameSprachvariable[language]"
                      aria-describedby="username"
                      autofocus
                      autocapitalize="off"
                      spellcheck="false"
                      autocorrect="off"
                      v-model="username"
                      @keydown="checkCapsLock"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span id="password" class="input-group-text">
                        <font-awesome-icon
                          icon="fa-duotone fa-key"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      type="password"
                      name="password"
                      class="form-control"
                      :placeholder="passwordSprachvariable[language]"
                      aria-describedby="password"
                      autocomplete="off"
                      autocapitalize="off"
                      spellcheck="false"
                      autocorrect="off"
                      v-model="password"
                      @keyup.enter="handleSubmit"
                      @keydown="checkCapsLock"
                    />
                  </div>
                  <p v-if="isCapsLockOn" style="color: red">
                    {{ capslockactivated[language] }}
                  </p>
                  <button class="btn btn-primary w-100 mb-3" @click="login">
                    {{ loginSprachvariable[language] }}
                  </button>
                  <div
                    class="hover-medium-blue d-inline-block"
                    style="cursor: pointer"
                    @click="navigateToResetPW"
                  >
                    {{ forgottenyourpassword[language] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import server from "../server";

export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
      isCapsLockOn: false,
      language: null,
      welcome: {
        de: "Willkommen",
        en: "Welcome",
        fr: "Bienvenue",
        it: "Benvenuti",
      },
      usernameSprachvariable: {
        de: "Benutzername",
        en: "User name",
        fr: "Nom d'utilisateur",
        it: "Nome utente",
      },
      passwordSprachvariable: {
        de: "Passwort",
        en: "Password",
        fr: "Mot de passe",
        it: "Password",
      },
      capslockactivated: {
        de: "Caps Lock ist aktiviert!",
        en: "Caps Lock is activated!",
        fr: "Caps Lock est activé !",
        it: "Il blocco delle maiuscole è attivato!",
      },
      loginSprachvariable: {
        de: "Login",
        en: "Login",
        fr: "Connexion",
        it: "Accesso",
      },
      forgottenyourpassword: {
        de: "Passwort vergessen?",
        en: "Forgotten your password?",
        fr: "Mot de passe oublié ?",
        it: "Avete dimenticato la password?",
      },
    };
  },
  created() {
    window.addEventListener("keyup", (event) => {
      if (event.key === "Enter" && this.username && this.password) {
        this.login();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("keyup");
  },
  computed: {
    loginUrl() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}/login/`;
      } else {
        return "/login/";
      }
    },
  },
  methods: {
    checkCapsLock(event) {
      this.isCapsLockOn = event.getModifierState("CapsLock");
    },
    async login() {
      const params = new URLSearchParams();
      params.append("username", this.username);
      params.append("password", this.password);

      try {
        await axios.post(this.loginUrl, params);
        let startseite = this.$router.resolve({ name: "ERP Startseite" });
        window.location.href = startseite.href;
      } catch (error) {
        console.log(error);
      }
    },
    navigateToResetPW() {
      this.$router.push({
        name: "resetpassword",
        query: {
          lang: this.language,
        },
      });
    },
  },
  mounted() {
    this.language =
      this.$route.query.lang || navigator.language || navigator.userLanguage;
    this.language = this.language.slice(0, 2);
    if (
      this.language !== "de" &&
      this.language !== "en" &&
      this.language !== "fr" &&
      this.language !== "it"
    ) {
      this.language = "de";
    }
  },
};
</script>

<style lang="scss" scoped>
.form-signin {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  padding: 15px;
}

.language-selection button {
  width: 30px;
}
</style>
